import React from "react";
import Layout from "../components/layout";
import HeaderTwo from "../components/header/header-two";
import StickyHeader from "../components/header/sticky-header";
import ServiceTwo from "../components/services/service-two";
import Footer from "../components/footer";

const HomeOne = () => {
  return (
    <Layout pageTitle="Auto Serwis - Tomasz Jatkowski">
      <HeaderTwo />
      <StickyHeader />
      <ServiceTwo />
      <Footer />
    </Layout>
  );
};

export default HomeOne;
